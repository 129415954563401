.highcharts-root {
  font-family: "Poppins", sans-serif !important;
  * {
    font-family: "Poppins", sans-serif !important;
  }
  .highcharts-credits {
    display: none;
  }
  .highcharts-title {
    fill: $primary !important;
    font-weight: 600;
    font-size: 18px;
  }
}
