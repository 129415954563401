@import "./var";
@import "./common";
@import "./highChart_custom.scss";
@import "./widget/widget";

.selectedFilter {
  div {
    margin-right: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 3px #ccc;
  background-color: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c7c7c7;
  border-radius: 8px;
}

.blueLink {
  color: $primary;
  margin-left: 7px;
  cursor: pointer;
  font-weight: 400;
}

.errorBoundary__text--error {
  text-align: center;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  margin-top: 180px;
  flex-direction: column;

  p {
    margin-bottom: 10px;
  }
}

.otp-field {
  display: flex;

  .otp-field__input {
    width: 42px;
    height: 42px;
    text-align: center;
    margin-right: 8px;
  }
}

// ======== theme dropdown menu =========
.themeDropdown_menu {
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(107, 108, 126, 0.25);
  border: none;
  padding: 5px 0;
  overflow: visible !important;

  .themeDropdown_menuIcon {
    margin-right: 12px;
    color: $iconColor;
    line-height: 1;
  }

  .themeDropdown_menuImage {
    height: 32px;
    width: 32px;
    margin-right: 0.8rem;
  }

  .themeDropdown_menuImage img {
    height: 100%;
    border-radius: 50%;
    width: 100%;
  }

  li {
    padding: 10px 18px;
    white-space: nowrap;
    transition: 0.2s linear;
    font-size: 14px;
    cursor: pointer;
    color: $textPrimary;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $hoverItem;
    }

    &:active {
      background-color: $secondary400;
      color: #fff;
    }
  }
}

// ======== theme dropdown menu end=========

// ======== searchBar =========
.searchBar {
  position: relative;
  height: 40px;
  display: inline-block;
  min-width: 320px;

  .searchIcon {
    position: absolute;
    height: 24px;
    width: 24px;
    top: 10px;
    left: 16px;
    line-height: 1;
  }

  input {
    height: 100%;
    width: 392px;
    padding: 0px 20px 0px 45px;

    &:focus {
      outline: none;
      border-color: $secondary;
    }

    &::placeholder {
      color: $iconColor;
    }
  }
}

// ======== searchBar end =========
// ==== sidebar scs =====
.sidebarCanvas {
  padding: 2rem 2rem;
}

.sidebarCanvasHeader {
  padding: 0;
}

.sidebarCanvasBody {
  padding: 0;
}

// ==== sidebar scs end =====

// ====theme select field ====
.theme_select_field {
  .selectWrap {
    height: 100%;

    .select__control {
      height: auto !important;
    }

    .select__value-container {
      flex-wrap: wrap !important;
    }

    .select__multi-value__remove {
      display: none;
    }

    .select__menu {
      display: none;
    }
  }

  .select__control {
    border: 1px solid #ccd1db !important;
    border-radius: 8px;
    font-size: 14px;
    color: $textPrimary;
    height: 40px;
    cursor: pointer;
  }

  .select__value-container {
    height: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;
    flex-wrap: nowrap;
  }

  &.container__diagnoses-select {
    .select__control {
      height: auto !important;

      .select__value-container {
        max-height: 100px;
        overflow: auto;
      }
    }

    .select__value-container {
      flex-wrap: wrap !important;
    }
  }

  &.container__medications-select {
    .select__control {
      height: auto !important;
    }

    .select__value-container {
      flex-wrap: wrap !important;
    }

    .menuItem_detail__medications {
      p {
        font-weight: bold;
        color: #333 !important;
        margin: 0 !important;
      }

      span {
        font-size: 13px !important;
        color: #555 !important;
      }
    }

    .select__multi-value {
      .menuItem_detail__medications {
        p {
          font-weight: normal;
          color: #333 !important;
          margin: 0 !important;
        }

        span {
          display: none;
        }
      }
    }
  }

  .select__indicator-separator {
    display: none;
  }

  .select__menu {
    border-radius: 8px;
    box-shadow: 0px 0px 8px rgba(107, 108, 126, 0.25);
    border: none;
    font-size: 14px;
    overflow: hidden;
    padding: 10px 0;
    z-index: 9;

    .select__option {
      padding: 10px 18px;
      white-space: normal;
      transition: 0.2s linear;
      font-size: 14px;
      cursor: pointer;
      color: $textPrimary;

      &:hover {
        background-color: $hoverItem;
        color: $textPrimary;
      }

      &:active {
        background-color: $secondary400;
        color: #fff;
      }

      &.select__option--is-selected {
        background-color: $secondary600;
        color: #fff;
      }
    }
  }

  .select__multi-value {
    background-color: $secondary100;
    border-radius: 10px;
    display: flex;

    .select__multi-value__remove {
      color: $iconColor;

      :hover {
        background-color: unset;
      }
    }
  }

  .menuItemWrap {
    display: flex;

    .menuItem_detail {
      display: flex;
      align-items: center;
    }

    .menuItem_check {
      display: none;
    }

    .menuItem_close {
      display: none;
    }
  }

  .menuItem_detail__diagnoses {
    p {
      font-weight: bold;
      color: #333 !important;
      margin: 0 !important;
    }

    span {
      font-size: 13px !important;
      color: #555 !important;
    }
  }

  .select__single-value {
    .menuItem_detail__diagnoses {
      p {
        font-weight: normal;
        color: #333 !important;
        margin: 0 !important;
      }

      span {
        display: none;
      }
    }
  }

  .select__multi-value {
    .menuItem_detail__diagnoses {
      p {
        font-weight: normal;
        color: #333 !important;
        margin: 0 !important;
      }

      span {
        display: none;
      }
    }
  }

  .primaryDoctorWrap {
    .select__option--is-selected {
      background-color: $hoverItem !important;

      .menuItem_detail {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-right: 20px;

        p {
          margin-left: 0px !important;
        }
      }

      .menuItem_check {
        display: flex;
        align-items: center;
        color: $secondary600;
      }
    }

    .select__single-value {
      .menuItemWrap {
        align-items: center;
        background-color: $secondary100;
        padding: 2px 7px;
        border-radius: 15px;

        .menuItem_detail {
          img {
            height: 24px !important;
            width: 24px !important;
            min-width: 24px;
            object-fit: cover;
          }

          p {
            margin-left: 7px !important;
            margin-bottom: 0;
          }
        }

        .menuItem_close {
          display: flex;
          align-items: center;
          margin-left: 10px;
          z-index: 1;
        }

        .select__clear-indicator {
          position: absolute !important;
          right: 200px !important;
        }
      }
    }

    .select__menu-list {
      display: flex;
      flex-direction: column;

      .select__option--is-selected {
        order: -1;
      }
    }
  }

  .filterDoctorWrap {
    .select__option--is-selected {
      background-color: $hoverItem !important;

      .menuItem_detail {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        padding-right: 20px;

        p {
          margin-left: 0px !important;
        }
      }

      .menuItem_check {
        display: flex;
        align-items: center;
        color: $secondary600;
      }
    }

    .select__single-value {
      .menuItemWrap {
        align-items: center;
        background-color: transparent;
        padding: 2px 10px;
        border-radius: 15px;

        .menuItem_detail {
          img {
            height: 24px !important;
            width: 24px !important;
            min-width: 24px;
            object-fit: cover;
          }

          p {
            margin-left: 8px !important;
          }
        }

        .select__clear-indicator {
          position: absolute !important;
          right: 200px !important;
        }
      }
    }

    .select__menu-list {
      display: flex;
      flex-direction: column;

      .select__option--is-selected {
        order: -1;
      }
    }
  }

  .select__placeholder {
    font-size: 14px;
    font-weight: 400;
    color: $iconColor;
  }
}

// ====theme select field end====

// ==== theme radio buttons scs start =====

.theme_radio_select {
  display: flex;

  .radio_select_btn {
    label {
      cursor: pointer;
      background-color: $secondary100;
      color: $headingColor;
      padding: 6px 8px;
      border-radius: 4px;
      text-align: center;
      font-size: 14px;
      line-height: 1.5;
      margin-right: 8px;
    }

    input {
      display: none;

      &:checked {
        & ~ label {
          background-color: $secondary400;
          color: #fff;
        }
      }
    }
  }
}

// ==== theme radio buttons scs end =====
.cardPdf {
  border: 1px solid #ccd1db;
  padding: 12px 10px 10px;
  display: flex;
  border-radius: 6px;
  min-width: 180px;
  max-width: 200px;
  position: relative;

  .cardPdf_icon {
    margin-right: 10px;

    svg {
      height: 20px;
      width: 16px;
    }
  }

  .cardPdf_content {
    line-height: 1;

    h6 {
      color: $textPrimary;
      font-size: 12px;
      margin-bottom: 2px;
      line-height: 1;
      word-break: break-word;
      padding-right: 15px;
    }

    span {
      color: $textSecondary;
      font-size: 10px;
      line-height: 1;
    }
  }

  .delete_pdf {
    position: absolute;
    right: 6px;
    top: 4px;
    color: $iconColor;
    padding-right: 4px;
    cursor: pointer;

    &:hover {
      color: $textPrimary;
    }

    svg {
      height: 11px;
      width: 11px;
    }
  }
}

.cardPdf.card__download {
  margin-bottom: 20px;
  min-width: 100%;
  max-width: 100%;
  align-items: center;

  .cardPdf_icon {
    line-height: 0;
  }

  .upload__icon {
    position: absolute;
    right: 6px;
    top: 6px;

    color: $textPrimary;
    padding-right: 4px;
    cursor: pointer;

    &:hover {
      color: $textSecondary;
    }

    svg {
      height: 14px;
      width: 14px;
    }
  }
}

// ======== theme check box menu======
.theme_checkBox_menu {
  min-width: 300px !important;
  border-radius: 8px;
  box-shadow: 0px 0px 8px rgba(107, 108, 126, 0.2);
  border: none;

  li {
    padding: 10px 18px;
    white-space: nowrap;
    transition: 0.2s linear;
    font-size: 14px;
    cursor: pointer;
    color: $textPrimary;

    &:hover {
      background-color: $hoverItem;
    }
  }
}

// =========== Date range picker customization ========
.theme_dateRangePicker {
  padding: 1rem;
  border: 1px solid #eaecf0;
  border-radius: 8px;

  //.rdrDayPassive {
  //  display: none;
  //}

  .rdrDefinedRangesWrapper,
  .rdrDateDisplayWrapper {
    display: none;
  }

  .rdrMonthName {
    color: $headingColor;
    font-size: 16px;
    text-align: center;
    font-weight: 600;
    padding-top: 0;
  }

  .rdrMonthsHorizontal {
    .rdrMonth {
      padding: 0 25px;
      width: 332px;

      &:first-child {
        padding-left: 0;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

  .rdrMonthAndYearWrapper {
    margin-bottom: -22px;
    padding-top: 0;
    height: auto;

    .rdrNextPrevButton {
      background-color: unset;
      color: $iconColor;

      i {
        height: 10px;
        width: 10px;
      }

      &:hover {
        background-color: transparent;

        i {
          color: $headingColor;
        }
      }
    }

    .rdrPprevButton {
      i {
        border: none;
        border-left: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        transform: rotate(45deg);
      }
    }

    .rdrNextButton {
      i {
        border: none;
        border-right: 2px solid currentColor;
        border-bottom: 2px solid currentColor;
        transform: rotate(-45deg);
      }
    }
  }

  .rdrMonthAndYearPickers {
    display: none;
  }

  .rdrInRange {
    background-color: #f0fbfd !important;
    // background-color: red !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    right: 0 !important;
    left: 0 !important;
  }

  // .rdrInRange:first-child, .rdrInRange:last-child {
  //   border-top-right-radius: 1.042em;
  //   border-bottom-right-radius: 1.042em;
  // }

  .rdrDay {
    height: 50px;
    border-radius: 50% !important;

    &.rdrDayDisabled {
      background-color: transparent;
    }
  }

  .rdrDayNumber {
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }

  .rdrDayToday {
    .rdrDayNumber {
      span {
        color: #41c3d8;
      }
    }
  }

  .rdrDayToday .rdrDayNumber span:after {
    width: 4px;
    height: 4px;
    border-radius: 50%;
  }

  .rdrDay:not(.rdrDayPassive) {
    .rdrInRange,
    .rdrStartEdge,
    .rdrEndEdge {
      ~ .rdrDayNumber {
        span {
          color: $textPrimary !important;
        }
      }
    }
  }

  .rdrDayInPreview,
  .rdrDayStartPreview,
  .rdrDayEndPreview {
    color: $secondary400 !important;
    z-index: 0;
    border: none;
    background-color: #f0fbfd;
  }

  .rdrDayInPreview {
  }

  .rdrDayStartPreview {
    background-color: transparent !important;
  }

  .rdrDayStartPreview.rdrDayEndPreview {
    border-radius: 50%;
    background-color: unset;
  }

  .rdrStartEdge,
  .rdrEndEdge {
    background-color: transparent !important;
    border: 1px solid $secondary400;
    border-radius: 50% !important;

    * {
      color: $textPrimary !important;
    }
  }
}

// =========== Date range picker customization end ========

.formSwitch {
  display: flex;
  align-items: center;

  input {
    height: 24px;
    width: 48px !important;
    margin-top: 0;
  }

  label {
    font-size: 14px;
    font-weight: 400;
    color: $textPrimary;
    margin-left: 10px;
  }
}

// ============= Password customization start ===========

.formFields {
  .labelWrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    label {
      margin-bottom: 0;
    }

    span {
      color: $primary;
      cursor: pointer;
      font-size: 12px;
    }
  }

  label {
    font-size: 12px;
    margin-bottom: 4px;
    color: #6c7893;
    font-weight: 400;
  }
}

.passwordEye {
  position: relative;

  input {
    padding-right: 20px;
    font-size: 16px;
    color: #6c7893;
    padding: 15px 35px 15px 15px;
  }

  .passwordEyeImg {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    width: 22px;
    line-height: 0;

    img {
      width: 100% !important;
    }
  }
}

.hidePassword {
  color: red;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-45deg);
    height: 1px;
    background-color: #a5adc0;
    width: 25px;
  }
}

// ============= Password customization end ===========

.offcanvas-title {
  h5 {
    font-weight: 600;
  }
}

.rdt_TableCell {
  font-size: 14px;

  div {
    font-size: 14px;
  }

  span {
    font-size: 14px;
  }
}

.rdt_TableCell {
  .dropdown-toggle {
    svg {
      color: #a5adc0;
    }
  }
}

.select__multi-value {
  div {
    font-weight: 400;
    font-size: 14px;
    color: black;
  }
}

.last_patient_row .rdt_TableRow:last-child .rdt_TableCell .themeDropdown_menu,
.last_patient_row
  .rdt_TableRow:nth-last-child(2)
  .rdt_TableCell
  .themeDropdown_menu,
.last_patient_row
  .rdt_TableRow:nth-last-child(3)
  .rdt_TableCell
  .themeDropdown_menu,
.last_patient_row
  .rdt_TableRow:nth-last-child(4)
  .rdt_TableCell
  .themeDropdown_menu {
  position: fixed !important;
}

.accrodionArrow .accordion-button:not(.collapsed)::after {
  transform: rotate(0deg) !important;
}

.accrodionArrow .accordion-button.collapsed::after {
  transform: rotate(180deg);
}

input[type="date"]::-webkit-inner-spin-button,
input#formDOB::-webkit-inner-spin-button,
input#formDOB::-webkit-calendar-picker-indicator,
input[type="date"]::-webkit-calendar-picker-indicator {
  color: red;
  width: 40px;
  height: 40px;
  z-index: 99999;
  position: relative;
  opacity: 0;
}

.teamTable .rdt_TableCell div {
  overflow: inherit !important;
}

.btntohref {
  background-color: transparent;
  color: #18338c;
  border-color: #18338c;
  padding: 10px 12px;
  line-height: 1;
  border-radius: 8px;
  display: inline-block;
  font-size: 1rem;
  border: 1px solid;
}

button.btn.outline.iconBtn.btnFilter {
  height: 40px;
  position: relative;
}

.applyFillter {
  .btnFilter {
    &:after {
      background: #41c3d8;
      position: absolute;
      top: -3px;
      right: -4px;
      width: 10px;
      height: 10px;
      content: "";
      display: inline-block;
      border-radius: 50%;
      border: none;
    }
  }
}

button.btn.outline.iconBtn.btnFilter:after {
  border: none;
}

button.btn.show {
  outline: none !important;
  border: 1px solid transparent;
}

.str-chat__li {
  button.btn.show {
    border: none !important;
  }
}

.btntohref svg {
  width: 16px;
  height: auto;
}

.primaryDoctorWrap .select__control {
  height: 100% !important;
}

.primaryDoctorWrap .select__control .select__single-value {
  white-space: normal;
}

.arrowDown {
  transform: rotate(180deg);
  display: inline-block;
}

.form-check-input {
  &:checked {
    background-color: $primary !important;
    border-color: $primary !important;
  }
}

.theme_select_field {
  .select__control--is-focused {
    .select__placeholder {
      display: none;
    }
  }
}

.select__menu {
  svg {
    width: 12px;
    height: 10px;

    path {
      fill: $primary;
    }
  }
}

.disable_btns {
  .btn:disabled {
    background: #a5adc0 !important;
    opacity: 1 !important;
  }
}

.clickable {
  cursor: pointer !important;
}

.counterTag {
  color: $primary;
  background-color: $secondary100;
  padding: 7px 8px;
  border-radius: 8px;
  margin-left: 5px;
}

.table_patient {
  margin-bottom: 50px;
  .rdt_TableCol:nth-child(2) {
    position: sticky !important;
    left: 0;
    background-color: #fff;
    z-index: 9;
  }
}

.rdt_TableCell {
  background-color: #ffffff;
}

.rdt_TableRow:nth-child(even) {
  background-color: rgb(248, 248, 248);

  .rdt_TableCell {
    background-color: rgb(248, 248, 248);
    transition: all 300ms;
  }
}

.rdt_TableRow:hover .rdt_TableCell {
  background-color: #aae5ea;
  transition: all 500ms;
}

.str-chat__message-attachment-card {
  background: transparent !important;
  margin: 0 !important;
  border: none !important;
}

.suggestion-header {
  font-size: 14px;
}

.container-suggestion {
  padding-left: 16px;
  padding-top: 5px;
  padding-bottom: 5px;

  .suggestion-item {
    display: flex;
    align-items: center;
    font-size: 14px;

    span {
      margin-left: 10px;
      font-size: 12px;
    }
  }

  span {
    margin-left: 30px;
    font-size: 12px;
  }
}

.container-suggestion.selected {
  background: var(--white-smoke);
  color: $primary;
  cursor: pointer;
}

.autoComplete {
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="date"],
  input[type="password"] {
    border: 0;
  }
}

.textField {
  input[type="text"],
  input[type="email"],
  input[type="number"],
  input[type="date"],
  input[type="password"] {
    border: 0;
  }
}

.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color) !important;
  background-color: var(--list-group-active-bg) !important;
  border-color: var(--list-group-active-border-color) !important;
}

.dataGrid {
  p {
    margin-bottom: 0;
  }
}

@media (max-width: 992px) {
  .searchBar {
    position: relative;
    height: 40px;
    display: inline-block;
    min-width: auto;
    input {
      width: 100%;
    }
  }
}
