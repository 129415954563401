.MuiDataGrid-footerContainer {
  flex-direction: row-reverse !important;
}

.MuiDataGrid-row {
  cursor: pointer;
}

.MuiDataGrid-columnHeaders {
  min-height: 40px !important;
  max-height: 40px !important;
}
